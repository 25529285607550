import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyBy9KC3NZO-2c8v4c7AVNJVs47AKvaU2ZQ",
    authDomain: "djangels-b04b6.firebaseapp.com",
    projectId: "djangels-b04b6",
    storageBucket: "djangels-b04b6.appspot.com",
    messagingSenderId: "291709790108",
    appId: "1:291709790108:web:6c7b0c0ca1a3186d844a59",
    measurementId: "G-BXPXDMQX9M"
};

firebase.initializeApp(config);

export default firebase.database();